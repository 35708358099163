import React from 'react';

import { Copy, Pill, TextField } from '@/components';
import { Nullable } from '@/types';
import { DataDef, DataDefProps, FilterType } from '../types';

const transformFilterValue = (value: Nullable<string>) => {
  if (value) {
    return value.trim();
  }
  return value;
};

export const stringFilter = ({
  field,
  filterField,
  title,
}: {
  field: string;
  filterField: string;
  title: string;
}): FilterType => ({
  field,
  transformDefaultValues: (values) => {
    const value = values[filterField];
    return {
      ...values,
      [filterField]: transformFilterValue(value) || '',
    };
  },
  transformValues: (values) => {
    const value = values[filterField];
    return {
      ...values,
      [filterField]: transformFilterValue(value) || undefined,
    };
  },
  component: () => (
    <TextField name={filterField} id={filterField} title={title} />
  ),

  pillView: (values, updateFilter) => {
    const value = values[filterField];
    if (value) {
      return (
        <Pill onClick={() => updateFilter({ [filterField]: undefined })}>
          {value}
        </Pill>
      );
    }
    return null;
  },
});

export function stringValue<O, T extends Record<string, any>>({
  title,
  field,
  filterField,
  enableCopy = false,
  getValue,
  ...values
}: DataDefProps<O, T> & {
  filterField?: string;
}): DataDef<O, T> {
  return {
    title,
    field,
    ...values,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field] || ' - ';
      if (enableCopy) {
        return <Copy value={value} />;
      }
      return <>{value}</>;
    },
    filter: filterField
      ? stringFilter({ field, title, filterField })
      : undefined,
  };
}
