import React from 'react';

import { Card, CardCloseButton, CardOptions, DetailList } from '@/components';
import formatMoney from '@/utils/formatter/formatMoney';
import { PlayerRewardCalculatorQuery } from './__generated__/usePlayerRewardCalculator';

export const PlayerStats = ({
  playerStats,
  lastBonusWasIn24h,
  isEligibleForReward,
}: {
  playerStats: PlayerRewardCalculatorQuery['player']['playerStats'];
  lastBonusWasIn24h: boolean;
  isEligibleForReward: boolean;
}) => {
  return (
    <Card
      size="lg"
      title="Player Stats"
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <DetailList
        items={[
          {
            label: 'Segment',
            value: playerStats?.playerCurrentSegment || '-',
          },
          {
            label: 'NGR',
            value: formatMoney(playerStats?.ngr, playerStats?.currency),
          },
          {
            label: 'NGR Since Last Manual Reward',
            value: formatMoney(
              playerStats?.ngrSinceLastManualReward,
              playerStats?.currency,
            ),
          },
          {
            label: 'Last Bonus At (Updated every 24h)',
            value: playerStats?.lastBonusAt || '-',
          },
          {
            label: 'Any reward in the last 24h?',
            value: lastBonusWasIn24h ? 'Yes' : 'No',
          },
          {
            label: 'Is player eligible for reward?',
            value: isEligibleForReward ? 'Yes' : 'No',
          },
        ]}
      />
    </Card>
  );
};
