import { useViewer } from '@/contexts/ViewerContext';
import { GameProvider, OrgEnum } from '@/globalTypes';
import { Nullable } from '@/types';

export type Operator = {
  name: string;
  path: string;
  id: OrgEnum;
  graphQLEndpoint: string;
  marketingAndFinanceEmbedUrl: Nullable<string>;
  rewardsGameProviders: GameProvider[];
};

export const allOperators: Operator[] = [
  {
    name: 'Infiniza',
    path: 'infiniza',
    id: OrgEnum.IN,
    graphQLEndpoint: process.env.GATSBY_API_URL!,
    marketingAndFinanceEmbedUrl: process.env.GATSBY_MARKETING_LANDING_URL,
    rewardsGameProviders: [
      GameProvider.Evolution,
      GameProvider.Pragmatic,
      GameProvider.Relax,
    ],
  },
  {
    name: 'Tekzia',
    path: 'tekzia',
    id: OrgEnum.MT,
    graphQLEndpoint: process.env.GATSBY_API_URL!,
    marketingAndFinanceEmbedUrl: process.env.GATSBY_MARKETING_LANDING_URL,
    rewardsGameProviders: [
      GameProvider.Evolution,
      GameProvider.Pragmatic,
      GameProvider.Relax,
    ],
  },
  {
    name: 'Frenwall',
    path: 'frenwall',
    id: OrgEnum.FW,
    graphQLEndpoint: process.env.GATSBY_API_URL!,
    marketingAndFinanceEmbedUrl: process.env.GATSBY_MARKETING_LANDING_URL,
    rewardsGameProviders: [
      GameProvider.Evolution,
      GameProvider.Pragmatic,
      GameProvider.Relax,
      GameProvider.Hub88,
    ],
  },
  {
    name: 'Herion',
    path: 'herion',
    id: OrgEnum.HE,
    graphQLEndpoint: process.env.GATSBY_API_URL!,
    marketingAndFinanceEmbedUrl: process.env.GATSBY_MARKETING_LANDING_URL,
    rewardsGameProviders: [
      GameProvider.Pragmatic,
      GameProvider.Aleaplay,
      GameProvider.Hub88,
    ],
  },
  {
    name: 'Fortune Forge',
    path: 'fortuneforge',
    id: OrgEnum.TFF,
    graphQLEndpoint: process.env.GATSBY_API_URL!,
    marketingAndFinanceEmbedUrl: process.env.GATSBY_MARKETING_LANDING_URL,
    rewardsGameProviders: [GameProvider.Hub88],
  },
  {
    name: 'Elmst LTD',
    path: 'elmst',
    id: OrgEnum.TFF,
    graphQLEndpoint: process.env.GATSBY_API_URL!,
    marketingAndFinanceEmbedUrl: process.env.GATSBY_MARKETING_LANDING_URL,
    rewardsGameProviders: [
      GameProvider.Evolution,
      GameProvider.Pragmatic,
      GameProvider.Relax,
    ],
  },
];

export const operatorsById = allOperators.reduce(
  (acc, value) => {
    acc[value.id] = value;
    return acc;
  },
  {} as Record<OrgEnum, Operator>,
);

export const getOperatorFromEnv = () => {
  const operator = process.env.GATSBY_OPERATOR!;

  const foundOperator = allOperators.find((a) => a.path === operator);

  return foundOperator;
};

export const useOperators = () => {
  const { viewer } = useViewer();

  return allOperators.filter(
    (a) => viewer?.identity.orgs.includes(a.id) || false,
  );
};
