import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from 'urql';

import {
  FileDrop,
  readFileAndReturnData,
  useFileDropFile,
} from '@/bits/file-drop';
import {
  Card,
  CardBody,
  ErrorMessage,
  NakedForm,
  SelectField,
  SubmitButton,
} from '@/components';
import { useTranslate } from '@/contexts';
import {
  BrandEnum,
  CloseAccountCauseV4,
  PlayerInformationInput,
} from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { useBrandValueOptions, useBrands } from '@/hooks/useBrands';
import { Nullable } from '@/types';
import { useCloseAccountCauses } from '@/utils';
import {
  BulkCreateExcludedPlayersMutation,
  BulkCreateExcludedPlayersMutationVariables,
} from './__generated__/component';

const BLOCK_STATIC_QUERY = graphql`
  query BulkCreateExcludedPlayersBlock {
    sanityBulkCreateExcludedPlayersBlock {
      title {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const bulkCreateExcludedPlayersMutation = gql`
  mutation BulkCreateExcludedPlayers(
    $playersInformationInput: [PlayerInformationInput!]!
    $brand: BrandEnum!
    $closeCause: CloseAccountCauseV4!
  ) {
    bulkCreateExcludedPlayers(
      playersInformationInput: $playersInformationInput
      brand: $brand
      closeCause: $closeCause
    ) {
      numberOfSuccesses
      failedPlayers {
        phoneNumber
        firstName
      }
    }
  }
`;

type FormValues = {
  brand: BrandEnum;
  closeCause: CloseAccountCauseV4;
  playersInformationInput: PlayerInformationInput[];
};

const parsePlayers = (players: string[]): { [key: string]: string }[] => {
  const headers = players[0].trim().split(',');

  return players.slice(1).map((player) => {
    const values = player.trim().split(',');
    const playerObject: { [key: string]: string } = {};

    headers.forEach((header, index) => {
      playerObject[header] = values[index];
    });

    return playerObject;
  });
};

const BulkAdjustmentsBlock = () => {
  const { t } = useTranslate();
  const { brands } = useBrands();
  const brandValueOptions = useBrandValueOptions(brands);
  const { dispatch, file } = useFileDropFile();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const closeAccountCauseOptions = useCloseAccountCauses();
  const isMounted = useIsMounted();

  const block =
    useStaticQuery<Queries.BulkCreateExcludedPlayersBlockQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityBulkCreateExcludedPlayersBlock;

  const [, bulkCreateExcludedPlayers] = useMutation<
    BulkCreateExcludedPlayersMutation,
    BulkCreateExcludedPlayersMutationVariables
  >(bulkCreateExcludedPlayersMutation);

  const defaultValues: FormValues = {
    brand: BrandEnum.AMOK,
    closeCause: CloseAccountCauseV4.CrossLicenseBlock,
    playersInformationInput: [],
  };

  const methods = useForm({ defaultValues });

  if (!block) {
    return null;
  }

  const onSubmit = (values: FormValues) => {
    if (!file) {
      return;
    }

    setErrorMessage(null);

    readFileAndReturnData(file)
      .then((players) => {
        const parsedPlayers = parsePlayers(players) as PlayerInformationInput[];

        bulkCreateExcludedPlayers({
          playersInformationInput: parsedPlayers,
          brand: values.brand,
          closeCause: values.closeCause || null,
        }).then((res) => {
          if (res.error?.message && isMounted) {
            setErrorMessage(res.error.message);
          }
          if (res.data?.bulkCreateExcludedPlayers && isMounted) {
            const excludedPlayers = res.data.bulkCreateExcludedPlayers;

            setErrorMessage(
              `Failed numbers: ${excludedPlayers.failedPlayers
                .map((player) => player.phoneNumber)
                .join(', ')}`,
            );

            feedback.success(
              `Number of excluded players: ${excludedPlayers.numberOfSuccesses}`,
            );

            methods.reset();
          }
        });
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <NakedForm
          className="grid gap-3 p-3"
          onSubmit={onSubmit}
          methods={methods}
        >
          <div className="grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <SelectField
              name="brand"
              title="Brand"
              options={brandValueOptions.options}
              required
            />
            <SelectField
              name="closeCause"
              id="create-excluded-player-form-block__closeCause"
              title="Close Cause"
              options={closeAccountCauseOptions}
              required
            />
          </div>
          <FileDrop
            dispatch={dispatch}
            file={file}
            accept={{ 'text/csv': ['.csv'] }}
            setErrorMessage={setErrorMessage}
          />
          <SubmitButton
            value={t(block.submit)}
            disabled={!!errorMessage || !file}
          />
          <ErrorMessage message={errorMessage} />
        </NakedForm>
      </CardBody>
    </Card>
  );
};

export default BulkAdjustmentsBlock;
