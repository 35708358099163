import { formatISO } from 'date-fns';
import { useMemo } from 'react';
import { gql, useQuery } from 'urql';

import { RangeDate } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  RgRiskAssessmentsQuery,
  RgRiskAssessmentsQueryVariables,
} from './__generated__/useRgRiskAssessment';
import { queryParams } from './queryParams';

const QUERY = gql`
  query RgRiskAssessments(
    $playerGlobalId: ID
    $rgCraRiskLevels: [RgCraRiskLevel!]
    $rgCraReviewStatus: [RgCraReviewStatus!]
    $calculatedAt: RangeDate
    $lastReviewedAt: RangeDate
    $orderBy: RgCraRiskAggregationOrderBy
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      rgRiskAssessments: rgCraRiskAssessments(
        playerGlobalId: $playerGlobalId
        rgCraRiskLevels: $rgCraRiskLevels
        rgCraReviewStatus: $rgCraReviewStatus
        calculatedAt: $calculatedAt
        lastReviewedAt: $lastReviewedAt
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            playerGlobalId
            players {
              id
              rawPlayerId
              brand {
                name
              }
            }
            riskLevel
            calculatedAt
            reviewStatusInfo {
              status
              lastReviewed
            }
          }
        }
      }
    }
  }
`;

export default function useRgRiskAssessment() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const calculatedAt = useMemo((): RangeDate | undefined => {
    if (query.from && query.to) {
      return {
        from: formatISO(query.from),
        to: formatISO(query.to),
      };
    }

    return undefined;
  }, [query.from, query.to]);

  const lastReviewedAt = useMemo((): RangeDate | undefined => {
    if (query.lastReviewedFrom && query.lastReviewedTo) {
      return {
        from: formatISO(query.lastReviewedFrom),
        to: formatISO(query.lastReviewedTo),
      };
    }
    return undefined;
  }, [query.lastReviewedFrom, query.lastReviewedTo]);

  const variables = {
    ...mapVariables(query),
    calculatedAt,
    lastReviewedAt,
  };

  const [{ data, fetching }, refresh] = useQuery<
    RgRiskAssessmentsQuery,
    RgRiskAssessmentsQueryVariables
  >({
    query: QUERY,
    variables,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.rgRiskAssessments.pageInfo),
    entries: data?.viewer.rgRiskAssessments?.edges?.map((edge) => edge?.node),
  };
}
