import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { accountStatusFragment } from '@/components/AccountStatus';
import { useTranslate } from '@/contexts';
import { AccountStatusEnum, CloseAccountCauseV4 } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { useCloseAccountCauses } from '@/utils';
import { assert } from '@/utils/error';
import {
  ClosePlayerAccountStatusMutation,
  ClosePlayerAccountStatusMutationVariables,
  GlobalClosePlayerAccountStatusMutation,
  GlobalClosePlayerAccountStatusMutationVariables,
  PlayerAccountStatusFormQuery,
  ReopenPlayerAccountStatusMutation,
  ReopenPlayerAccountStatusMutationVariables,
} from './__generated__/PlayerAccountStatusForm';

const query = graphql`
  query SanityPlayerAccountStatusForm {
    sanityPlayerAccountStatusForm {
      title {
        ...SanityLocaleString
      }
      reasonLabel {
        ...SanityLocaleString
      }
      closeAccountSubmit {
        ...SanityLocaleString
      }
      reopenAccountSubmit {
        ...SanityLocaleString
      }
      reopenWithoutCooldown {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerAccountStatusForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      ...AccountStatusFragment
    }
  }
  ${accountStatusFragment}
`;

const closeMutation = gql`
  mutation ClosePlayerAccountStatus(
    $playerId: ID!
    $closeCause: CloseAccountCauseV4!
  ) {
    closePlayerAccountV4(playerId: $playerId, closeCause: $closeCause) {
      ...AccountStatusFragment
    }
  }
  ${accountStatusFragment}
`;

const globalCloseMutation = gql`
  mutation GlobalClosePlayerAccountStatus(
    $playerGlobalId: ID!
    $closeCause: CloseAccountCauseV4!
  ) {
    closeGlobalPlayerAccounts(
      playerGlobalId: $playerGlobalId
      closeCause: $closeCause
    ) {
      players {
        ...AccountStatusFragment
      }
    }
  }
  ${accountStatusFragment}
`;

const reopenMutation = gql`
  mutation ReopenPlayerAccountStatus(
    $playerId: ID!
    $overrideCooldown: Boolean
  ) {
    reopenPlayerAccount(
      playerId: $playerId
      overrideCooldown: $overrideCooldown
    ) {
      ...AccountStatusFragment
    }
  }
  ${accountStatusFragment}
`;

const CloseAccountForm: FC<{
  form: Queries.SanityPlayerAccountStatusFormQuery['sanityPlayerAccountStatusForm'];
  cause: CloseAccountCauseV4;
}> = ({ form, cause }) => {
  const { t } = useTranslate();
  const { playerId, playerGlobalId } = useParams();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const closeAccountCauseOptions = useCloseAccountCauses();

  const [closePlayerAccountState, closePlayerAccount] = useMutation<
    ClosePlayerAccountStatusMutation,
    ClosePlayerAccountStatusMutationVariables
  >(closeMutation);

  const [closeGlobalPlayerAccountState, closeGlobalPlayerAccount] = useMutation<
    GlobalClosePlayerAccountStatusMutation,
    GlobalClosePlayerAccountStatusMutationVariables
  >(globalCloseMutation);

  const fetching = playerId
    ? closePlayerAccountState.fetching
    : closeGlobalPlayerAccountState.fetching;

  const { close } = useModalContext();

  const defaultValues = {
    closeCause: cause,
  };

  const onSubmit = (values: typeof defaultValues) => {
    if (playerId) {
      closePlayerAccount({
        playerId,
        closeCause: values.closeCause,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }

    if (playerGlobalId) {
      closeGlobalPlayerAccount({
        playerGlobalId,
        closeCause: values.closeCause,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      className="grid grid-cols-2 sm:grid-cols-3 gap-6"
    >
      <SelectField
        title={t(form?.reasonLabel)}
        name="closeCause"
        id="PlayerAccountStatusForm__closeCause"
        options={closeAccountCauseOptions}
      />
      <ErrorMessage message={errorMessage} />
      <SubmitButton value={t(form?.closeAccountSubmit)} disabled={fetching} />
    </Form>
  );
};

const ReopenAccountForm: FC<{
  playerId: PlayerAccountStatusFormQuery['player']['id'];
  form: Queries.SanityPlayerAccountStatusFormQuery['sanityPlayerAccountStatusForm'];
  canOpenWithoutCooldown: boolean;
}> = ({ playerId, form, canOpenWithoutCooldown }) => {
  const { t } = useTranslate();
  const isMounted = useIsMounted();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);

  const [reopenPlayerAccountState, reopenPlayerAccount] = useMutation<
    ReopenPlayerAccountStatusMutation,
    ReopenPlayerAccountStatusMutationVariables
  >(reopenMutation);

  const { close } = useModalContext();

  const defaultValues = {
    overrideCooldown: false,
  };

  const onSubmit = (values: typeof defaultValues) => {
    if (playerId) {
      reopenPlayerAccount({
        playerId,
        overrideCooldown: values.overrideCooldown,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!playerId) {
    return null;
  }

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      className="grid grid-cols-2 sm:grid-cols-3 gap-6"
    >
      {canOpenWithoutCooldown && (
        <CheckboxField
          name="overrideCooldown"
          id="ReopenAccountForm__canOpenWithoutCooldown"
          title={t(form?.reopenWithoutCooldown)}
        />
      )}
      <ErrorMessage message={errorMessage} />
      <SubmitButton
        value={t(form?.reopenAccountSubmit)}
        disabled={reopenPlayerAccountState.fetching}
      />
    </Form>
  );
};

const PlayerAccountStatusForm: FC<{
  playerId: string;
  canOpenWithoutCooldown: boolean;
  isGlobal: boolean;
}> = ({ playerId, canOpenWithoutCooldown = false, isGlobal }) => {
  const { t } = useTranslate();
  const staticData =
    useStaticQuery<Queries.SanityPlayerAccountStatusFormQuery>(query);
  const form = staticData.sanityPlayerAccountStatusForm;

  assert(form, 'missing form data');

  const [{ data }] = useQuery<PlayerAccountStatusFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  if (!data) {
    return null;
  }

  const player = data.player;
  const accountIsOpen = player.accountStatus.status === AccountStatusEnum.Open;
  const showCloseAccountForm = accountIsOpen || player.shouldReopenAt;
  const cause = player.accountStatus.cause as CloseAccountCauseV4;

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        {showCloseAccountForm ? (
          <CloseAccountForm form={form} cause={cause} />
        ) : (
          <ReopenAccountForm
            playerId={playerId}
            form={form}
            canOpenWithoutCooldown={canOpenWithoutCooldown}
          />
        )}
      </div>
    </Card>
  );
};

export default PlayerAccountStatusForm;
