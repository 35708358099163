import { useMemo } from 'react';

import { CloseAccountCauseV4 } from '@/globalTypes';

const closeAccountCauseMap: Record<CloseAccountCauseV4, string> = {
  AbusiveBehaviour: 'Abusive Behaviour',
  AdverseMedia: 'Adverse Media',
  BlockCustomerRequest: 'Block Customer Request',
  ClosedInternalDecision: 'Closed Internal Decision',
  ConfirmedFraud: 'Confirmed Fraud',
  ConfirmedPromotionAbuse: 'Confirmed Promotion Abuse',
  CrossLicenseBlock: 'License Block',
  ExceededSOWF: 'Exceeded SOWF',
  FailureProvideDD: 'Failure Provide DD',
  MLRO: 'MLRO',
  MigrationCause: 'Migration Cause',
  MultipleAccounts: 'Multiple Accounts',
  NonCAPExtremeCRARisk: 'Non CAP Extreme CRA Risk',
  PEPListMatch: 'Pep List Match',
  PendingInternalChecks: 'Pending Internal Checks',
  PermanentCustomerRequest: 'Permanent Customer Request',
  ProhibitedCountry: 'Prohibited Country',
  ResponsibleGaming: 'Responsible Gaming',
  RgBrandClosure: 'RG Brand Closure',
  RgPendingInternalChecks: 'RG Pending Internal Checks',
  SanctionsListMatch: 'Sanctions List Match',
  SuspectedFraud: 'Suspect Fraud',
  SuspectedPromotionAbuse: 'Suspected Promotion Abuse',
  TemporaryCustomerRequest: 'Temporary Customer Request',
  ToBeCategorized: 'To Be Categorized',
  AccountIssues: 'Account Issues',
  InsufficientGameChoices: 'Insufficient Game Choices',
  NotEnoughBonusesOrPromotions: 'Not Enough Bonuses Or Promotions',
  PaymentIssues: 'Payment Issues',
  BrandClosure: 'Brand Closure',
};

export const useCloseAccountCauses = () => {
  return useMemo(
    () => [
      {
        label: 'Not specified',
        value: '',
      },
      ...Object.entries(closeAccountCauseMap).map(([value, label]) => ({
        label,
        value,
      })),
    ],
    [],
  );
};
