import { GameProvider } from '@/globalTypes';
import { getOperatorFromEnv } from './operators';

const currentOperator = getOperatorFromEnv();

const gameProviders: GameProvider[] =
  currentOperator?.rewardsGameProviders || [];

export const rewardGameProviders = Object.values(gameProviders).map(
  (provider) => {
    return { label: provider, value: provider };
  },
);
